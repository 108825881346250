import { posthog } from "posthog-js";
const posthogPlugin = {
    install: (app) => {
        app.config.globalProperties.$posthog = posthog.init('phc_OeDI7MK0P417Vr93Umk0Y0malFhVewXSrtz3ad6A1ZI', {
            // api_host: 'https://us.i.posthog.com', // original address usually got blocked by adblockers
            api_host: 'https://water.batuice.com', // reverse proxy through cloudflare workers
        });
    },
};
export default posthogPlugin;
