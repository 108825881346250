<script lang="ts" setup>
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";
import NormalLayout from "@/layouts/NormalLayout.vue";
import timezone from "@/constant/timezone";

const userStore = useUserStore()
const {info, packages} = storeToRefs(userStore)


function dateOnly(time: string): string {
  const dateFormat = new Intl.DateTimeFormat('id-ID', {timeZone: timezone.AsiaJakarta, dateStyle: 'long'})
  return dateFormat.format(new Date(time))
}
</script>

<template>
  <NormalLayout>
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <div class="page-pretitle">Your</div>
            <h2 class="page-title">Profile</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="col-md-6 col-lg-4">

          <div v-if="info" class="card">
            <div class="card-body p-4 text-center">
              <h3 class="m-0 mb-1"><a href="#">{{ info.name }}</a></h3>
              <div class="text-secondary">{{ info.email }}</div>
              <template v-for="(date, key) in packages">
                <!--<div v-if="key === 'registered'" class="mt-3">-->
                <!--<span class="badge bg-secondary-lt">{{ key }}</span>-->
                <!--</div>-->
                <template v-if="key !== 'free' && key !== 'registered'">
                  <div class="mt-3">
                    <span class="badge bg-yellow-lt">{{ key }}</span>
                  </div>
                  <div class="mt-1">
                    <span class="text-secondary">hingga {{ dateOnly(date) }}</span>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <p v-else>
            <i>Silahkan login terlebih dahulu</i>
          </p>
        </div>
      </div>
    </div>
  </NormalLayout>
</template>

<style scoped></style>