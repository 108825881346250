<script lang="ts" setup>
import type {Ref} from 'vue'
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import type {UnsubscribeFunc} from "pocketbase";
import PocketBase from "pocketbase";
import ItemImage from "@/components/kincir/ItemImage.vue"
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

const userStore = useUserStore()
const {rules} = storeToRefs(userStore)

const props = defineProps(['duration'])

// const props = defineProps<{
//   duration: string
// }>()

const pb = new PocketBase(import.meta.env.VITE_API_URL)
const summary: Ref<Summary> = ref({
  teddy: 0, baloon: 0, princess: 0, ice: 0, mahkota: 0, pelampung: 0, tinju: 0, camera: 0,
})

let unsubscribeLive: UnsubscribeFunc | null

const mapDurationsLiveID: { [key: string]: string } = {
  '30m': 'kc_summary_30m_',
  '1h': 'kc_summary_1h__',
  '2h': 'kc_summary_2h__',
  '4h': 'kc_summary_4h__',
  '12h': 'kc_summary_12h_',
  '24h': 'kc_summary_24h_',
  '48h': 'kc_summary_48h_',
}

onMounted(() => {
  fetchSummary()

  subscribeSummary()
})

watch(() => props.duration, async (newDuration: string, oldDuration: string) => {
  console.log(`duration changed from ${oldDuration} to ${newDuration}`)

  // get & show outcomes
  await fetchSummary()

  // unsubscribe
  await unsubscribeSummary()

  // resubscribe
  await subscribeSummary()
})

onBeforeUnmount(() => {
  unsubscribeSummary()
})

async function fetchSummary() {
  console.log('fetchSummary')

  // reset
  summary.value = {
    teddy: 0, baloon: 0, princess: 0, ice: 0, mahkota: 0, pelampung: 0, tinju: 0, camera: 0,
  }

  // hit
  const record = await pb.collection('live')
      .getOne(mapDurationsLiveID[props.duration], {});

  summary.value = record.content
}

async function subscribeSummary() {
  console.log('subscribeSummary', props.duration)

  unsubscribeLive = await pb.collection('live').subscribe(mapDurationsLiveID[props.duration], (e) => {
    console.log('summary incoming', {action: e.action, content: e.record.content})
    summary.value = e.record.content
  })
}

async function unsubscribeSummary() {
  console.log('unsubscribeSummary')
  if (unsubscribeLive) {
    await unsubscribeLive()
  }
}

interface Summary {
  teddy: number,
  baloon: number,
  princess: number,
  ice: number,
  mahkota: number,
  pelampung: number,
  tinju: number,
  camera: number,
}
</script>

<template>
  <div v-if="rules?.kincir.duration[props.duration]" class="card">
    <div class="card-body">
      <div v-if="rules" class="row justify-content-between">
        <div class="col text-center">
          <ItemImage class="me-1" item="mahkota" width="25"/>
          {{ summary.mahkota }}
        </div>
        <div class="col text-center">
          <ItemImage class="me-1" item="princess" width="25"/>
          {{ summary.princess }}
        </div>
        <div class="col text-center">
          <ItemImage class="me-1" item="camera" width="25"/>
          {{ summary.camera }}
        </div>
        <div class="col text-center">
          <ItemImage class="me-1" item="teddy" width="25"/>
          {{ summary.teddy }}
        </div>
      </div>
    </div>
  </div>
</template>