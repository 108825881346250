<script lang="ts" setup>
import {useUserStore} from "@/stores/user"
import {storeToRefs} from "pinia";

const props = defineProps(['target'])

const userStore = useUserStore()
const {info} = storeToRefs(userStore)

</script>

<template>
  <div :id="props.target" class="modal modal-blur fade" tabindex="-1">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        <div v-if="info" class="modal-body">
          <div class="modal-title text-center mt-4">
            {{ info.name }} <br>
            {{ info.email }}
          </div>
        </div>
        <div v-else class="modal-body">
          <div class="modal-title text-center">Login to your Account?</div>
          <button class="btn btn-outline-primary w-100" data-bs-dismiss="modal" @click="userStore.googleLogin()">
            Login With Google
          </button>
          <p class="text-center mt-3 mb-0 text-muted">
            Gunakan browser chrome <br>
            atau nonaktifkan pop-up bloker.
          </p>
        </div>
        <div v-if="info" class="modal-footer">
          <div class="w-100">
            <div class="row">
              <div class="col">
                <a class="btn btn-secondary w-100" data-bs-dismiss="modal" @click="userStore.logout()">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>