import { defineStore } from 'pinia';
import { ref } from "vue";
import PocketBase from "pocketbase";
import { posthog } from "posthog-js";
import router from "@/router/index";
export const useUserStore = defineStore('user', () => {
    const info = ref(null);
    const packages = ref(null);
    const rules = ref(null);
    const pb = new PocketBase(import.meta.env.VITE_API_URL);
    const authRedirectURL = new URL(window.location.origin + '/auth').toString();
    listenAuthStoreChanged();
    function listenAuthStoreChanged() {
        // triggered when these method is called:
        //  - pb.authStore.clear()
        //  - pb.authStore.set()
        pb.authStore.onChange(async () => {
            console.log('authStore.changed', {
                isValid: pb.authStore.isValid,
                model: pb.authStore.model,
            });
            // isValid = true, can be users session is expired, but still have token
            if (pb.authStore.isValid) {
                // logged in
                await syncLoggedInInfo();
            }
            else {
                // not logged in
                await syncLogoutState();
            }
            // sync user extended info
            await syncUserExtendedInfo();
        }, true);
    }
    async function syncLoggedInInfo() {
        console.log('syncLoggedInInfo');
        if (pb.authStore.model === null) {
            return;
        }
        // sync user info with pocketbase
        const model = pb.authStore.model;
        info.value = {
            id: model.id,
            name: model.name,
            email: model.email,
        };
        // assign identity to posthog
        posthogIdentify();
    }
    async function login() {
        const authData = await pb.collection('users').authWithOAuth2({ provider: 'google' });
        console.log('Logging in');
        if (pb.authStore.model === null) {
            return;
        }
        await updateUserName(authData.meta?.name);
    }
    async function getGoogleProvider() {
        const authMethods = await pb.collection('users').listAuthMethods();
        for (const provider of authMethods.authProviders) {
            if (provider.name === 'google') {
                return provider;
            }
        }
        throw new Error('Google provider not found');
    }
    async function googleLogin() {
        const provider = await getGoogleProvider();
        localStorage.setItem('provider', JSON.stringify(provider));
        localStorage.setItem('lastPageURL', window.location.href);
        window.location.href = provider.authUrl + authRedirectURL;
    }
    async function authenticateGoogle(code) {
        console.log('authenticateGoogle');
        // load the previously stored provider's data
        const provider = JSON.parse(localStorage.getItem('provider') ?? '{}');
        const authData = await pb.collection('users')
            .authWithOAuth2Code(provider.name, code, provider.codeVerifier, authRedirectURL);
        // update account name
        if (pb.authStore.model !== null) {
            await updateUserName(authData.meta?.name);
        }
        // redirect to the last page
        const lastPageURL = localStorage.getItem('lastPageURL');
        if (lastPageURL !== null) {
            window.location.href = lastPageURL;
            return;
        }
        await router.push('/');
    }
    async function updateUserName(name) {
        const userModel = pb.authStore.model;
        if (userModel?.name !== '') {
            return;
        }
        userModel.name = name;
        await pb.collection('users').update(userModel.id, userModel);
        console.log('name updated to', name);
    }
    // syncUserExtendedInfo should be called either user logged in or not
    async function syncUserExtendedInfo() {
        console.log('syncUserExtendedInfo');
        // prepare request
        const headers = { Authorization: 'Bearer ' + pb.authStore.token };
        const url = new URL("/api/v2/user", import.meta.env.VITE_API_URL);
        // hit
        const response = await fetch(url, { headers });
        if (response.status != 200) {
            return;
        }
        const userInfo = await response.json();
        rules.value = userInfo.rules;
        packages.value = userInfo.packages;
    }
    // posthogIdentify sends user info to posthog
    function posthogIdentify() {
        if (pb.authStore.model) {
            posthog.identify(info.value?.id, {
                name: info.value?.name,
                email: info.value?.email,
            });
        }
    }
    async function logout() {
        console.log('logout');
        pb.authStore.clear();
    }
    async function syncLogoutState() {
        console.log('syncLogoutState');
        // reset state
        info.value = null;
        rules.value = null;
        packages.value = null;
        // reset posthog identify
        // posthog.reset() // want to assign same user even though the do log out, so don't reset
    }
    return { info, rules, packages, login, logout, googleLogin, authenticateGoogle };
});
