<script lang="ts" setup>
import MinimalLayout from "@/layouts/MinimalLayout.vue"
import {RouterLink} from "vue-router";
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

const userStore = useUserStore()
const {info} = storeToRefs(userStore)
</script>

<template>
  <MinimalLayout>
    <div class="text-center mb-4">
      <a class="navbar-brand navbar-brand-autodark" href="/">
        <img alt="Batu Ice Logo" height="40" src="/logo.png">
      </a>
    </div>
    <div class="card card-md">
      <div v-if="info" class="card-body">
        <h2 class="h2 text-center mb-4">
          Hai {{ info.name }},
          {{ info.email }}
        </h2>
        <div class="row">
          <div class="col">
            <button class="btn w-100 btn-secondary" @click="userStore.logout()">Logout</button>
          </div>
        </div>
      </div>
      <div v-else class="card-body">
        <h2 class="h2 text-center mb-4">Login to your account</h2>
        <div class="row">
          <div class="col">
            <button class="btn w-100" @click="userStore.googleLogin()">Login with Google</button>
          </div>
        </div>
      </div>
      <div class="hr-text">or</div>
      <div class="card-body">
        <div class="row mt-2">
          <div class="col">
            <RouterLink class="btn w-100" to="/">
              Back to Home
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </MinimalLayout>
</template>
