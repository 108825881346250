<script lang="ts" setup>
</script>

<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <slot/>
    </div>
  </div>
</template>
