import * as Sentry from "@sentry/vue";
const sentryPlugin = {
    install: (app) => {
        Sentry.init({
            app,
            dsn: "https://af60d5d017d0095b15688fd2e97bb8b3@o4507420221571072.ingest.us.sentry.io/4507420223668224",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0,
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/batuice\.com\//],
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            enabled: import.meta.env.VITE_APP_ENV === "production",
            environment: import.meta.env.VITE_APP_ENV,
        });
    },
};
export default sentryPlugin;
