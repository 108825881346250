<script lang="ts" setup>
import {RouterLink} from "vue-router";
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";
import {IconCrown, IconDice3, IconHelpHexagon, IconMoon, IconPremiumRights, IconSun} from "@tabler/icons-vue";
import {themeMode} from "@/stores/theme_mode";

const userStore = useUserStore()
const {info} = storeToRefs(userStore)
</script>

<template>
  <header class="navbar navbar-expand-md d-print-none">
    <div class="container-xl">
      <button aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler" data-bs-target="#navbar-menu" data-bs-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <RouterLink class="navbar-brand-image" to="/">
          <img alt="Batu Ice Logo" class="navbar-brand-image" src="/logo.png">
        </RouterLink>
      </div>
      <div class="navbar-nav flex-row order-md-last">
        <div class="d-none d-md-flex me-3">
          <button class="nav-link px-0 hide-theme-dark" data-bs-placement="bottom" data-bs-toggle="tooltip"
                  title="Enable dark mode" @click="themeMode.switchMode()">
            <IconMoon class="icon"/>
          </button>
          <button class="nav-link px-0 hide-theme-light" data-bs-placement="bottom" data-bs-toggle="tooltip"
                  title="Enable light mode" @click="themeMode.switchMode()">
            <IconSun class="icon"/>
          </button>
        </div>
        <div class="nav-item dropdown">
          <a aria-label="Profile Menu" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown">
            <template v-if="info">
              <span class="avatar">{{ info.name.slice(0, 3) }}</span>
              <div class="d-none d-xl-block ps-2">
                <div>{{ info.name }}</div>
                <div class="mt-1 small text-muted">{{ info.email }}</div>
              </div>
            </template>
            <template v-else>
              <span class="avatar">Hi</span>
              <div class="d-none d-xl-block ps-2">
                <div>Guest</div>
                <div class="mt-1 small text-muted">Free</div>
              </div>
            </template>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <template v-if="info">
              <RouterLink class="dropdown-item" to="/profile">Profile</RouterLink>
              <div class="dropdown-divider m-0"></div>
              <button class="dropdown-item" @click="userStore.logout()">Logout</button>
            </template>
            <button v-else class="dropdown-item" data-bs-target="#login-modal" data-bs-toggle="modal">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>

  <nav>
    <header class="navbar-expand-md">
      <div id="navbar-menu" class="collapse navbar-collapse">
        <div class="navbar">
          <div class="container-xl">
            <ul class="navbar-nav">
              <li :class="{'active': $route.path.startsWith('/kincir')}" class="nav-item">
                <RouterLink class="nav-link" to="/kincir">
                  <IconCrown class="icon me-1"/>
                  <span class="nav-link-title">Kincir</span>
                </RouterLink>
              </li>
              <li :class="{'active': $route.path.startsWith('/dadu')}" class="nav-item">
                <RouterLink class="nav-link" to="/dadu">
                  <IconDice3 class="icon me-1"/>
                  <span class="nav-link-title">Dadu</span>
                </RouterLink>
              </li>
              <!--<li class="nav-item" :class="{'active': $route.path.startsWith('/help')}">-->
              <!--  <RouterLink class="nav-link" to="/help">-->
              <!--    <IconHelp class="me-1"/>-->
              <!--    <span class="nav-link-title">Bantuan</span>-->
              <!--  </RouterLink>-->
              <!--</li>-->
              <li :class="{'active': $route.path.startsWith('/pricing')}" class="nav-item">
                <RouterLink class="nav-link" to="/pricing">
                  <IconPremiumRights class="icon me-1"/>
                  <span class="nav-link-title">Paket</span>
                </RouterLink>
              </li>
              <li :class="{'active': $route.path.startsWith('/about')}" class="nav-item">
                <RouterLink class="nav-link" to="/about">
                  <IconHelpHexagon class="icon me-1"/>
                  <span class="nav-link-title">About</span>
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  </nav>
</template>

