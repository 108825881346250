export class themeMode {
    static switchMode() {
        const theme = localStorage.getItem('tablerTheme');
        console.log('current theme', theme);
        if (theme !== 'dark') {
            console.log('set theme to dark');
            localStorage.setItem('tablerTheme', 'dark');
        }
        else {
            console.log('set theme to light');
            localStorage.setItem('tablerTheme', 'light');
        }
        location.reload();
    }
}
