import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import PageNotFound from "@/views/PageNotFound.vue";
import LoginView from "@/views/authentication/LoginView.vue";
import KincirLiveView from "@/views/KincirLiveView.vue";
import ProfileView from "@/views/ProfileView.vue";
const index = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/kincir',
            name: 'kincir-live',
            component: KincirLiveView,
        },
        {
            path: '/kincir/download',
            name: 'kincir-download',
            component: () => import('@/views/KincirDownloadView.vue'),
        },
        {
            path: '/dadu',
            name: 'dadu',
            component: () => import('@/views/DaduView.vue'),
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: () => import('@/views/PackageView.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
        },
        {
            path: '/auth',
            name: 'auth',
            component: () => import('@/views/authentication/AuthenticateView.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            component: ProfileView,
        },
        {
            path: '/help',
            name: 'help',
            component: () => import('@/views/HelpView.vue'),
        },
        {
            path: '/about',
            name: 'about',
            // component: AboutView,
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/AboutView.vue'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: PageNotFound,
        },
    ]
});
export default index;
