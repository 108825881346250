<script lang="ts" setup>
import type {Ref} from "vue"
import {onBeforeUnmount, onMounted, ref} from "vue"

const props = defineProps(['lastDateTime', 'lifetimeSec'])

let isLiveIntervalID: number = 0
const isLive: Ref<boolean> = ref(true)

onMounted(async () => {
  isLiveIntervalID = window.setInterval(() => {
    const last = new Date(props.lastDateTime)
    const now = new Date()

    const diff = Math.abs(now.getTime() - last.getTime()) / 1000
    // console.log('live check', {diff: diff, now: now, last: last})
    // console.log('live check', {diff: diff})

    if (diff >= props.lifetimeSec) {
      // interval 32 second gap for each round
      isLive.value = false
      clearInterval(isLiveIntervalID)

      console.log('outdated')
    }
  }, 1000)

  console.log('SetInterval ID:', isLiveIntervalID)
})

onBeforeUnmount(async () => {
  // Remove live interval check
  console.log('ClearInterval', isLiveIntervalID)
  clearInterval(isLiveIntervalID)
})

console.log('SetInterval ID:', isLiveIntervalID)
</script>

<template>
  <span v-if="isLive" class="status status-green">
    <span class="status-dot"/> Live
  </span>
  <span v-else class="status">
    <span class="status-dot"/> Offline
  </span>
</template>

