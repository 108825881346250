<script lang="ts" setup>
import NormalLayout from "@/layouts/NormalLayout.vue";
import {useHead} from "@unhead/vue";
import {IconCircleArrowRightFilled, IconCrown, IconDice3} from "@tabler/icons-vue";
import Helper from "@/helper";

// SEO
useHead({
  title: 'Batu Ice | Pola Kincir dan Dadu Litmatch',
  meta: [
    {
      name: 'description',
      content: 'Pahami pola Kincir Litmatch, analisa pola-pola sepanjang hari.'
    }
  ],
  link: [
    {rel: 'canonical', href: Helper.getCanonicalURL()},
  ],
})
</script>

<template>
  <NormalLayout>
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <div class="page-pretitle">Catatan</div>
            <h1 class="page-title">Batu Ice</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Page body -->
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-sm-6">
            <div class="card card-md">
              <div class="card-stamp card-stamp-lg">
                <div class="card-stamp-icon bg-azure">
                  <IconCrown class="icon"/>
                </div>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-10">
                    <h3 class="h1">Kincir Litmatch</h3>
                    <div class="markdown text-secondary">
                      Lihat catatan <strong>Kincir</strong> 30 menit hingga 48 jam terakhir, secara live
                    </div>
                    <div class="mt-3">
                      <RouterLink class="btn btn-primary" to="/kincir">
                        <IconCircleArrowRightFilled class="icon"/>
                        Lihat History Kincir
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card card-md">
              <div class="card-stamp card-stamp-lg">
                <div class="card-stamp-icon bg-azure">
                  <IconDice3 class="icon"/>
                </div>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-10">
                    <h3 class="h1">Dadu Litmatch</h3>
                    <div class="markdown text-secondary">
                      Lihat catatan <strong>Dadu</strong> 2 jam terakhir secara live
                    </div>
                    <div class="mt-3">
                      <RouterLink class="btn btn-primary" to="/dadu">
                        <IconCircleArrowRightFilled class="icon"/>
                        Lihat History Dadu
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </NormalLayout>
</template>

<style scoped></style>