<script lang="ts" setup>
import ItemImage from "@/components/kincir/ItemImage.vue";

const props = defineProps(['result'])

const statusColors: Record<string, string> = {
  ice: 'opacity-50',
  tinju: 'opacity-50',
  pelampung: 'opacity-50',
  baloon: 'opacity-50',
  camera: 'status-green',
  princess: 'status-purple',
  teddy: 'status-pink',
  mahkota: 'status-yellow',
}
</script>

<template>
  <ItemImage :item="props.result" class="me-4"/>
  <span :class="statusColors[props.result]" class="status">
    {{ props.result }}
  </span>
</template>