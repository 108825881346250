<script lang="ts" setup>
import type {Ref} from 'vue'
import {onBeforeUnmount, onMounted, ref, watch} from 'vue'
import type {UnsubscribeFunc} from 'pocketbase'
import PocketBase from 'pocketbase'
import LiveStatus from "@/components/LiveStatus.vue"
import KincirItem from "@/components/kincir/KincirItem.vue"
import timezone from "@/constant/timezone"
import {RouterLink} from "vue-router";

const props = defineProps(['duration'])

const pb = new PocketBase(import.meta.env.VITE_API_URL)
const items: Ref<Outcome[]> = ref([])
const latestDateTime: Ref<string> = ref('')
const isFeatureAvailable: Ref<boolean> = ref(true)

let unsubscribeLive: UnsubscribeFunc

const mapDurationsLabel: { [key: string]: string } = {
  '30m': '30 menit',
  '1h': '1 jam',
  '2h': '2 jam',
  '4h': '4 jam',
  '12h': '12 jam',
  '24h': '24 jam',
  '48h': '48 jam',
}

enum UnavailableAction {
  Login = 'login',
  Subscribe = 'subscribe',
}

const mapUnavailableAction: { [key: string]: UnavailableAction } = {
  '1h': UnavailableAction.Login,
  '2h': UnavailableAction.Subscribe,
  '4h': UnavailableAction.Subscribe,
  '12h': UnavailableAction.Subscribe,
  '24h': UnavailableAction.Subscribe,
  '48h': UnavailableAction.Subscribe,
}

onMounted(async () => {
  // first time fetch & show outcomes
  await fetchOutcome()

  // subscribe to new outcome
  await subscribeOutcome()
})

watch(() => props.duration, async (newDuration: string, oldDuration: string) => {
  console.log(`duration changed from ${oldDuration} to ${newDuration}`)

  // get & show outcomes
  await fetchOutcome()
})

watch(isFeatureAvailable, (newVal: boolean, oldVal: boolean) => {
  // enable / disable subs based on feature is available or not

  console.log(`isFeatureUnavailable ${oldVal} => ${newVal}`)

  if (newVal === oldVal) return

  if (newVal) {
    subscribeOutcome()
  } else {
    unsubscribeOutcome()
  }
})

onBeforeUnmount(async () => {
  await unsubscribeOutcome()
})

async function fetchOutcome() {
  console.log('fetchOutcome', props)

  // reset table
  items.value = []

  // prepare request
  const headers = {Authorization: 'Bearer ' + pb.authStore.token}
  const url = new URL("/api/v2/kincir/live", import.meta.env.VITE_API_URL)
  url.searchParams.append("duration", props.duration)

  // hit
  const response = await fetch(url, {headers})

  console.log('fetchOutcome got response', response.status)

  // show/hide upgrade package message
  isFeatureAvailable.value = response.status !== 403

  if (response.status != 200) {
    return
  }

  const resultList = await response.json()
  console.log('got resultList', resultList.length)
  resultList.forEach((item: any, key: number) => {
    // convert to outcome type
    const outcome = dataToOutcome(item);

    if (key == 0) {
      // store newest data as last date time
      latestDateTime.value = item.date_time
      console.log('latest', outcome)
    }

    // append to end index
    items.value.push(outcome)
  })
}

async function subscribeOutcome() {
  console.log('subscribeOutcome')

  unsubscribeLive = await pb.collection('live').subscribe('kc_latest______', (e) => {
    console.log('outcome incoming', {action: e.action, content: e.record.content})
    const item: any = e.record.content

    if (e.action === 'create' || e.action === 'update') {
      // convert to outcome type
      const outcome = dataToOutcome(item);

      // update latest date time
      latestDateTime.value = item.date_time;
      console.log('latest', outcome)

      // inject outcome to first index
      items.value.unshift(outcome)

      // remove oldest result
      if (items.value.length > 1) {
        items.value.pop()
      }
    }
  })
}

async function unsubscribeOutcome() {
  console.log('unsubscribeOutcome')
  if (unsubscribeLive) {
    await unsubscribeLive()
  }
}

function dataToOutcome(data: any): Outcome {
  const timeFormat = new Intl.DateTimeFormat('id-ID', {timeZone: timezone.AsiaJakarta, timeStyle: 'medium'})
  const dateFormat = new Intl.DateTimeFormat('id-ID', {timeZone: timezone.AsiaJakarta, dateStyle: 'medium'})

  return {
    id: data.id,
    result: data.result,
    round: data.round,
    date: dateFormat.format(new Date(data.date)),
    time: timeFormat.format(new Date(data.date_time)),
  }
}

// Types
type Outcome = {
  id: string,
  round: number,
  result: string,
  date: string,
  time: string,
};
</script>

<template>
  <div class="card">
    <div class="card-body border-bottom">
      <div class="row">
        <div class="col">
          <p class="m-0 text-muted">
            Outcome {{ mapDurationsLabel[duration] }} terakhir <span>({{ items.length }} item)</span></p>
        </div>
        <div v-if="isFeatureAvailable" class="col text-end">
          <LiveStatus :last-date-time="latestDateTime" lifetime-sec="37"/>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-vcenter">
        <thead>
        <tr>
          <th class="ps-3">Time & Date</th>
          <th>Round</th>
          <th>Outcome</th>
        </tr>
        </thead>
        <tbody>
        <TransitionGroup name="slide">
          <tr v-for="item in items" :key="item.id">
            <td class="ps-3">
              <div class="text-nowrap">{{ item.time }}</div>
              <div class="text-nowrap text-muted">
                <small>{{ item.date }}</small>
              </div>
            </td>
            <td>{{ item.round }}</td>
            <td>
              <KincirItem :result="item.result"/>
            </td>
          </tr>
        </TransitionGroup>
        <tr v-if="!isFeatureAvailable">
          <td class="p-3" colspan="3">
            <i v-if="mapUnavailableAction[duration] == UnavailableAction.Login">
              <a data-bs-target="#login-modal" data-bs-toggle="modal" href="">Login</a> dulu agar bisa lihat
              {{ mapDurationsLabel[duration] }}
              terakhir.
            </i>
            <i v-if="mapUnavailableAction[duration] == UnavailableAction.Subscribe">
              Fitur berbayar,
              <RouterLink to="/pricing">beli paket</RouterLink>
              dulu ya...
            </i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.7s ease;
}
</style>