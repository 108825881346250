import './assets/main.css'

import {createApp} from 'vue'
import {createPinia} from 'pinia';
import App from './App.vue'
import router from './router/'
import vueGtag from 'vue-gtag';
import posthogPlugin from './plugins/posthog';
import sentryPlugin from "./plugins/sentry";
import {createHead} from "@unhead/vue";

const pinia = createPinia()
const head = createHead()
const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(vueGtag, {
    config: {
        id: 'G-8YR4MFVWC9',
    },
}, router)
app.use(posthogPlugin)
app.use(sentryPlugin)
app.use(head)

console.log('env', import.meta.env.VITE_APP_ENV)

app.mount('#app')
